import React, {useContext} from 'react'
import HeadingContext from './headingcontext'

function Heading(props){
    const baseHeadingLevel = useContext(HeadingContext)
    const headingLevel = parseInt(baseHeadingLevel) + parseInt(props.modifier)
    const HeaderTag = 'h' + (headingLevel > 6 ? 6 : headingLevel)

    return <HeaderTag {...props}>{props.children}</HeaderTag>
}

export default Heading