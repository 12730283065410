import React, {Fragment} from 'react'
import dayjs from "dayjs";
import DayEventList from "./dayeventlist";

function DayList(props) {

    const buildEventList = (events) => {
        let daysEvents = []
        let currentEventDate = undefined
        let out = []
        let loopLength = props.sliceCount;
        if(events.length < props.sliceCount) {
            loopLength = events.length
        }

        for(let c=0; c<loopLength; c++)
        {
            let eventDate = dayjs(events[c].startsOn)
            if(eventDate.isAfter(currentEventDate,'day') && c !== 0)
            {
                out.push(<DayEventList key={currentEventDate.format('YYMMDD')} events={daysEvents} />)
                daysEvents = []
            }
            daysEvents.push(events[c])
            currentEventDate = eventDate
        }
        if(daysEvents.length > 0) {
            out.push(<DayEventList key={currentEventDate.format('YYMMDD')} events={daysEvents} />)
        }
        return out
    }

    return (
        <div id="events-display">
            {buildEventList(props.events)}
        </div>
    )
}

export default DayList
