import React from 'react'

function Location(props) {
    const content = props.name
    const onlineLink = () => {
        if ( props.onlineLocation ) {
            let linkDesc = 'Join Event Online'
            let linkURL = props.onlineLocation.toString().toLocaleLowerCase()

            if (linkURL.includes('zoom')){
                if (linkURL.includes('register')){
                    linkDesc = 'Register for this Zoom meeting'
                }
                else{
                    linkDesc = 'Join this Zoom meeting'
                }
            }
            else if (linkURL.includes('eventbrite')){
                linkDesc = 'Register for this Eventbrite meeting'
            }

            return (
                <p><a href={props.onlineLocation}>{linkDesc}</a></p>
            )
        }

    }
    return (
        <div>
            <p className="griz-hub-events__description">{props.name}</p>
            {onlineLink()}
        </div>
    )
}

export default Location
