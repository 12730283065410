import React, {Fragment} from 'react'
import dayjs from 'dayjs'

function isNextDay(startDateTime,endDateTime) {
    return startDateTime.add(1,'day').isSame(endDateTime,'day')
}

function EventTimeFrame(props) {
    const startDateTime = dayjs(props.startsOn)
    const endDateTime = dayjs(props.endsOn)

    if(startDateTime.isSame(endDateTime,'day') ) {
        return (
            <Fragment>
                {startDateTime.format('MMM D, YYYY h:mm a')} - {endDateTime.format('h:mm a')}
            </Fragment>
        )
    }

    if(endDateTime.format('HH:mm') === '00:00' && isNextDay(startDateTime,endDateTime)) return (
        <Fragment>{startDateTime.format('MMM D, YYYY')} All day</Fragment>
    )

    return (
        <Fragment>
            {startDateTime.format('MMM D, YYYY h:mm a')} - {endDateTime.format('MMM D, YYYY h:mm a')}
        </Fragment>
    )
}

export default EventTimeFrame

export { isNextDay } 