import React, {Fragment} from 'react'

function Loading(props) {
    if(props.isLoading) return (
        <div className="progress">
            <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: props.width || '100%'}} />
            <span className='sr-only'>Loading</span>
        </div>
    )
    return (<Fragment />)
}

export default Loading
