import React from 'react'
import Event from './event'

function EventList(props) {
    return (
        <ul className={'griz-hub-events__events'}>
            {props.events.map(event => (
                <Event key={event.id} data={event} />
            ))}
        </ul>
    )
}
export default EventList
