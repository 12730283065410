import React from 'react'

function GrizHubLink(props) {
    if(props.visibility === 'Public'){
        return (
            <a className={'button_primary button'} href={'https://umt.campuslabs.com/engage/event/' + props.id}>
                <span className="button_primary__text button-text">View on GrizHub</span>
                <svg fill="none" viewBox="0 0 17 15" className="button_primary__arrow" >
                    <path fill="currentColor" d="M17 6.611 9.56.235 7.317 3.03A55.553 55.553 0 0 0 13.6 6.565l-.073.254a46.744 46.744 0 0 0-9.486-.901H0v3.164h4.04c3.187.03 6.368-.264 9.486-.878l.074.254a57.174 57.174 0 0 0-6.283 3.512l2.243 2.795L17 8.389V6.61Z"/>
                </svg>
            </a>
        )
    } else {
        return ''
    }
}

export default GrizHubLink
