import React, {Fragment, useEffect, useState} from 'react'

function SearchInput(props) {
    const [term,setTerm] = useState('')
    const onSubmit = (e) => {
        e.preventDefault()
    }

    const handleChange = (e) => {
        setTerm(e.target.value)
    }

    useEffect(() => {
        props.searchHandler(term)
    },[term])

    const resultCountDisplay = () => {
        if(props.dataSource === 'search')
            return <div id="resultsCount" className="form-text text-muted" >{props.resultCount} results</div>
        if(term.length > 0)
            return <div id="resultsCount" className="form-text text-muted">No search results.</div>

    }
    return(
        <form onSubmit={onSubmit} role="search" aria-label="">
            <div className="filter-search-sec mb-60 m-mb-40 mt-1">
                <label className="filter-heading" id="filterSearch">Search Events:</label>
                <div className="filter-search">
                    <div aria-labelledby="filterSearch" className="search" role="search">
                        <div className="container">
                            <div className="row m-0 justify-content-center">
                                <div className="col-sm-12 p-0">
                                    <div className="row justify-content-between">
                                        <input
                                            aria-controls="events-display"
                                            aria-label="search"
                                            className="form-control"
                                            id="filter-search"
                                            placeholder="Search Events"
                                            type="search"
                                            value={term}
                                            onChange={handleChange}
                                            style={{width: 'calc(100% - 60px)'}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div role="status">{resultCountDisplay()}</div>
            </div>
        </form>
    )



}



export default SearchInput
