import React, {Fragment} from 'react'

function EventOnline(props){
    if(props.addressName === 'Online'){
        return (
            <Fragment> | online</Fragment>
        )
    }
    else{
        return ''
    }
}

export default EventOnline
