import React, { Fragment } from 'react'

const ShowMoreButton = (props) => {

    if( props.count < props.length ) return (
        <button onClick={ props.onClick } className="btn btn-primary">Show More Events</button>
    )
    return <Fragment></Fragment>
}

export default ShowMoreButton
