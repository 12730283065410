import React, {Fragment} from 'react'
import dayjs from 'dayjs'
import EventList from './eventlist'
import Heading from './heading'

const DayEventList = (props) => {
    const startDateTime = dayjs(props.events[0].startsOn)
    return <Fragment>
        <div className="callout callout--md">
            <span className="callout__text">{startDateTime.format('dddd, MMMM D')}</span>
        </div>
        <EventList {...props} />
    </Fragment>
}

export default DayEventList
