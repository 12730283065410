import React, { Fragment } from 'react'
import GrizHubLink from "./grizhublink"
import Heading from "./heading"
import OrgLink from "./orglink"
import Location from "./location"
import EventPermaLink from "../eventpermalink";
const BASE_URL = process.env.MIX_BASE_URL


function EventDetail(props) {
    const description = props.description.replace(/(<[^>]+) style=".*?"/ig, '$1');

    let wrapperClass = 'griz-hub-events__description-wrapper ';

    return (
        <Fragment>
            <div className={ wrapperClass }>
                <header className={'griz-hub-events__description-header'}>Description</header>
                <p className="griz-hub-events__description" dangerouslySetInnerHTML={{__html: description}}/>

                <header className={'griz-hub-events__description-header'}>Location</header>
                <Location {...props.address} />
                <OrgLink {...props.organization} />
            </div>
        </Fragment>
    )

}

export default EventDetail;
