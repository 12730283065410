import React, {Fragment} from 'react'

function OrgLink(props){
    if (props.visible) {
        return (
            <p><a href={props.url}>More events hosted by {props.name}</a></p>
        )
    }
    return (<Fragment />)
}

export default OrgLink
