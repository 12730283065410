import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Loading from './loading'
import ShowMoreButton from './showmorebutton'
import HeadingContext from './headingcontext'
import SearchInput from './searchinput'
import elasticlunr from 'elasticlunr'
import DayList from "./dayList";

const API_BASE_URL = process.env.MIX_BASE_URL


function Events(props) {
    const [data, setData] = useState({ totalItems: 0, events: []})
    const [sliceCount,setSliceCount] = useState(5)
    const [isLoading, setIsLoading] = useState(false);
    const [index, setIndex] = useState();
    const [events, setEvents] = useState([]);
    const displaySearch = (props.displaysearch ?? false) === 'Yes';

    useEffect( () => {
        const fetchData = async () => {
            setIsLoading(true)
            const result = await axios(
                API_BASE_URL+'api/events' +
    		    (props.organizationid ? ('?organizationIds='+props.organizationid) : '')
            )
            setData(result.data)
            setIsLoading(false)
        }
        fetchData()
    }, [])

    useEffect( ()=> {
        let idx = elasticlunr(function() {
            this.setRef('id')
            this.addField('name')
            this.addField('description')
            data.events.forEach(function (event) {
                this.addDoc(event)
            },this)
        })
        setIndex(idx)
    },[data])


    const showMore = () => {
        setSliceCount(sliceCount + 5)
    }

    const searchHandler = (value) => {
        if(index !== undefined)
        {
            let foundEvents = []
            const searchResults = index.search(value,{expand: true});
            data.events.forEach(function(event) {
                searchResults.forEach(function(match){
                    if(event.id == match.ref){
                        foundEvents.push(event)
                    }
                })
            })
            setSliceCount(5)
            setEvents(foundEvents)
        }
    }

    let dayList
    let dataSetLength = data.events.length;
    let dataSource = 'all'
    if(events.length > 0) {
        dataSetLength = events.length
        dataSource = 'search'
        dayList = <DayList events={events} sliceCount={sliceCount}/>
    }
    else {
        dayList = <DayList events={data.events} sliceCount={sliceCount}/>
    }

    return (
        <div className="o-events">
            {displaySearch?<SearchInput searchHandler={searchHandler} resultCount={dataSetLength} dataSource={dataSource}/>:null}
            <Loading isLoading={isLoading} />
            <HeadingContext.Provider value={props.headinglevel ?? 2}>
            { dayList }
            </HeadingContext.Provider>
            <ShowMoreButton onClick={showMore} count={sliceCount} length={dataSetLength}  />
        </div>
    )

}

export default Events
