import React, {Fragment} from 'react'

const BASE_URL = process.env.MIX_EVENT_BASE_URL

function EventPermaLink(props)
{
    if(BASE_URL === undefined)
        return <Fragment />

    const link = BASE_URL + props.id
    return (
        <a href={link} className={props.className} target={'_blank'}>
            {props.children}
        </a>
    )
}

export default EventPermaLink
