import React from 'react'
import ReactDOM from 'react-dom'
import Events from './events'
import 'react-app-polyfill/ie11';

if (document.getElementById('events-calendar')) {
    const propsContainer = document.getElementById("events-calendar");
    const props = Object.assign({}, propsContainer.dataset);
    ReactDOM.render(<Events {...props} />,document.getElementById('events-calendar'))
}
